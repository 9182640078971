import logo from './logo.png';
import './App.css';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-W15PPXPZ5B"; 
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <header className="App-header">
              <img src={logo} className="App-logo" alt="Oy Helsingin Tietotekniikkapalvelut Ab" />
              <p>
                Cloud based financial integration solution design and developement
              </p>
            </header>
          </div>
        </div>
        <div class="row">
          <footer>
            <div class="row py-2">
              <div class="col-2"></div>
              <div class="col-2">Tel: +358 9 530 6050</div>
              <div class="col-4">Simonkatu 12 B, 00100 HELSINKI </div>

              <div class="col-2"> Email: info(at)httpoy.fi </div>
              <div class="col-2"></div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
